// 项目的公共样式

.global-mask {
  background: transparent;
}

// html{
//   overflow-y: scroll;
// }

// :root {
//   overflow-x: auto;
//   overflow-y: hidden;
// }

// :root body {
//   position: absolute;
// }

// body {
//   width: 100vw;
//   overflow: hidden;
// }

.p-page {
  min-width: calc(100% - 30px);
  min-height: calc(100% - 30px);
  // border: 1px solid #ddd;
  // padding: 20px;
}

.m-page {
  width: 100%;
  min-height: 100%;
  padding: 15px 7px 0px 7px;
}

//element-ui样式调整
.el-checkbox__input.is-checked .el-checkbox__inner {
  background: #EB3443;
  border-color: #EB3443;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #EB3443;
  border-color: #EB3443;
}

.el-input.is-disabled .el-input__inner {
  background: #f5f5f5;
  color: #606060;
}
.el-textarea.is-disabled .el-textarea__inner {
  background: #f5f5f5;
  color: #606060;
}

.el-table-column--selection .cell {
  padding-right: 10px;
}

.el-input-width-100 {
  .el-form-item {
    width: 100%;
  }
}

.el-dialog-padding-30 {
  .el-dialog__body {
    padding: 30px 40px;
  }
}

.el-dialog-title-center {
  .el-dialog__header {
    text-align: center;
  }
}

.el-message-box {
  max-width: 100%;
}

.el-message-box__wrapper {
  .el-message-box__btns button:nth-child(1) {
    float: right;
    margin-left: 10px;
  }
}

.el-calendar__header {
  justify-content: flex-end;

  &>.el-calendar__title {
    margin-right: 10px;
  }
}

.el-calendar__title {
  font-size: 17px;
}

.el-table {
  .el-table__header-wrapper {
    th.is-left {
      text-align: left;
    }
  }
}

//移动端element-ui样式调整
.m-dialog {
  .el-dialog {
    width: 96% !important;
    margin-top: 3vh !important;

    .el-form-item__label {
      white-space: nowrap;
    }
  }
}

.m-page {
  .el-button {
    padding: 0 15px;
    height: 40px;
  }
}

.m-dialog-date {
  .el-calendar__title {
    font-size: 15px;
  }

  .el-dialog {
    width: 96% !important;
    margin-top: 3vh !important;

    .el-form-item__label {
      white-space: nowrap;
    }
  }

  .el-dialog__body {
    padding: 10px;
  }

  .el-calendar__body {
    padding: 0px;
  }
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.3s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-25px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(25px);
}

.drop-transform-leave-active,
.drop-transform-enter-active {
  transition: all 0.3s;
}

.drop-transform-enter {
  opacity: 0;
}

.drop-transform-leave-to {
  opacity: 0;
}

/*滚动条样式*/
::-webkit-scrollbar {
  border-radius: 8px;
  width: 8px;
  height: 8px;
}

//::-webkit-scrollbar-track {
//	border-radius: 8px;
//	-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
//	background: linear-gradient(90deg, #efefef 0%, #fff 78%, #efefef 100%);
//}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background: linear-gradient(90deg, #efefef 0%, #fff 78%, #efefef 100%);
}

::-webkit-scrollbar-thumb:vertical {
  background: linear-gradient(0deg, #efefef 0%, #fff 78%, #efefef 100%);
}

.record-sp-conatiner-pc {
  .table-row-text {
    font-size: 15px;
    white-space: pre-wrap;
  }

  .sp-record-titie {
    font-size: 16px;
    margin-top: 30px;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .sp-record-titie:nth-of-type(1) {
    margin-top: 0px;
  }
}

.record-sp-conatiner-m {
  .table-row-text {
    font-size: 15px;
    white-space: pre-wrap;
  }

  .sp-record-titie {
    font-size: 16px;
    margin-top: 30px;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .sp-record-titie:nth-of-type(1) {
    margin-top: 0px;
  }
}

.eval-show-conatiner-pc {
  .eval-item {
    margin-bottom: 20px;

    &>span {
      font-size: 15px;
    }

    .eval-star {
      display: flex;
      align-items: center;
      font-size: 15px;

      &>span {
        display: inline-block;
        width: 60px;
        vertical-align: middle;
        transform: translateY(-1px);
      }
    }
  }
}

.tutor-serve-summary {
  .ql-container.ql-snow {
    height: 500px;

    .ql-editor {

      p,
      li {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}

.row-btn-fix {
  vertical-align: middle;
  margin-left: 4px;
}

.row-btn-fix.el-button--small {
  height: 33px;
  line-height: 5px;
}

.m-page .row-btn-fix.el-button--small {
  height: 40px;
  line-height: 6px;
}

.el-tree-node__label {
  font-size: 16px;
  font-family: SourceHanSansSC;
  font-weight: 400;
  color: #343434;
}

.shop-setting-header-tab-p {
  display: flex;
  width: 100%;

  .tab-item {
    flex: 1;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    background: #ffffff;
    color: #343434;
    cursor: pointer;
    transition: all 0.2s;
  }

  .tab-item.actived {
    color: #ffffff;
    background: #32BEF9;
    position: relative;
  }

  .tab-item.actived::after {
    content: "";
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border: 10px solid #32BEF9;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
}

.le-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.el-table .row-color-purple {
  background: rgb(171, 145, 242);

  span {
    color: #fff;
  }
}

.el-table .row-color-red {
  background: #32BEF8;

  span {
    color: #fff;
  }
}

.el-table .row-color-pink {
  background: #f291cb;

  span {
    color: #fff;
  }
}

.el-table .row-color-yellow {
  background: #a9a300;

  span {
    color: #fff;
  }
}

.el-table .row-color-orange {
  background: #ffa200;

  span {
    color: #fff;
  }
}

.el-table .row-color-blue {
  background: #00a6ff;

  span {
    color: #fff;
  }
}

.el-table .row-color-lightblue {
  background: #b0c8ff;

  span {
    color: #fff;
  }
}

.footer-icp {
  position: fixed;
  bottom: 50px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20px;
    height: 20px;
  }

  a {
    color: #888;
    margin-left: 5px;
  }
}

.layer-icp-pc {
  width: 1200px;
  padding-left: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  margin-top: 30px;

  img {
    width: 20px;
    height: 20px;
  }

  a {
    color: #888;
    margin-left: 5px;
  }
}

.layer-icp-m {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  margin-top: 30px;

  img {
    width: 20px;
    height: 20px;
  }

  a {
    color: #888;
    margin-left: 5px;
  }
}

.col-form-status-wrapper {
  border-radius: 4px;
  border: 1px solid #efefef;
  padding: 10px 0;
  margin-bottom: 22px;

  .label-item {
    display: flex;
    align-items: center;

    .label-name {
      text-align: right;
      font-size: 0.16rem;
      color: #606266;
      padding: 0 10px 0 0;
      width: 130px;
      box-sizing: border-box;
      margin: 20px 0px;
    }

    .item-val {
      font-size: 0.16rem;
    }
  }
}

.el-form {

  .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before {
    color: #EB3443;
  }

  .el-form-item.is-error .el-input__inner,
  .el-form-item.is-error .el-input__inner:focus,
  .el-form-item.is-error .el-textarea__inner,
  .el-form-item.is-error .el-textarea__inner:focus {
    border-color: #EB3443;
  }

  .el-form-item__error,
  .el-form-item.is-error .el-input__validateIcon {
    color: #EB3443;
  }
}

.el-message--error {
  border-color: #ffb2b9;
  background-color: #fff1f2;

  .el-message__content,
  .el-message__icon {
    color: #EB3443;
  }
}

.el-tag {
  transition: none !important;
}